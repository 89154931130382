import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './permission'
import 'element-ui/lib/theme-chalk/index.css'
import 'vue-side-catalog/lib/vue-side-catalog.css' // 文章目录样式
import hljs from 'highlight.js' // 代码高亮
import 'highlight.js/styles/atom-one-dark.css'// 代码高亮样式
import Viewer from 'v-viewer' // 图片预览插件
import 'viewerjs/dist/viewer.css' // 图片预览样式
import { blogById, loadFile, loadDefaultImg } from '@/utils/tools.js'
import '@/assets/ali-icon-font/iconfont.css' // 阿里矢量图标

Vue.config.productionTip = false
// 挂载全局方法
Vue.prototype.blogById = blogById
Vue.prototype.loadFile = loadFile
Vue.prototype.loadDefaultImg = loadDefaultImg

Vue.use(Element, {
  size: 'medium'
})

// 全局注册图片预览组件
Vue.use(Viewer)
// Viewer.setDefaults({
//   Options: { inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source' }
// })

// 需要高亮的标签块 注册指令 v-highlight
Vue.directive('highlight', function (el) {
  const blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
