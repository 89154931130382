import router from '@/router'
import defaultBg from '@/assets/bg/defaultBg.gif'

const baseURL = process.env.VUE_APP_BASE_API

// 加载默认图片
export function loadDefaultImg(e) {
  e.target.src = defaultBg
}

// 文章详情跳转(开启新页面)
export function blogById(blogId) {
  const routeData = router.resolve('/article/' + blogId)
  window.open(routeData.href, '_blank')
}

// 加载文件路径
export function loadFile(fileName) {
  if (fileName === undefined || fileName === '') {
    return undefined
  }
  return baseURL + '/file/files/load-file/' + fileName
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    var part = encodeURIComponent(propName) + '='
    if (value !== null && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof value[key] !== 'undefined') {
            const params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}
