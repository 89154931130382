import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })

// 路由守卫 前
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

// 路由守卫 后
router.afterEach(() => {
  NProgress.done()
})
