import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) { // 路由反复点击出错catch
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: () => import('@/views/index.vue'),
    children: [
      { path: '/', name: 'Home', component: () => import('@/views/hemo.vue') },
      { path: '/subject', name: 'Subject', component: () => import('@/views/subject.vue') },
      { path: '/archived', name: 'Archived', component: () => import('@/views/archived.vue') },
      { path: '/msg-board', name: 'Msgboard', component: () => import('@/views/msgboard.vue') },
      { path: '/about', name: 'About', component: () => import('@/views/about.vue') },
      { path: '/info-list/:sortId?/:tagId?/:keywords?/:model?', name: 'InfoList', component: () => import('@/views/infolist.vue') },
      { path: '/article/:blogId', name: 'Article', component: () => import('@/views/article.vue') }
    ]
  },
  { path: '/404', component: () => import('@/views/404') },
  { path: '/*', component: () => import('@/views/404') }
]

const router = new VueRouter({
  mode: 'history', // 路由不显示#
  routes
})

export default router
